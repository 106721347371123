import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  selectUser,
  selectUserData,
  selectUserType,
  setUserData,
} from "../../features/userSlice";
import { db } from "../../utils/firebase";
import { Icon } from "@material-ui/core";
import firebase from "firebase";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
// import Axios from "axios";
import { rURL } from "../../utils/Links";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Axios from "axios";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

const Invite = ({
  route,
  navigation,
  data1,
  Id,
  handleClose,
  coachId,
  handleClose1,
}) => {
  let win = null;
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(null);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("DD-MM-YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).add(30, "days").format("DD-MM-YYYY")
  );
  const [frequency, setFrequency] = useState("once in a week");
  const [followUpFrequency, setFollowUpFrequency] = useState("");
  const [diet, setDiet] = useState("");
  const [carbs, setCarbs] = useState(0);
  const [fat, setFat] = useState(0);
  const [protein, setProtein] = useState(0);
  const [calories, setCalories] = useState(0);
  const [weight, setWeight] = useState(0);
  const [athleteData, setAtheleteData] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const [data, setdata] = useState(data1);
  const [tokens, setTokens] = useState();
  const [loading, setLoading] = useState(false);
  const [gmeetLink, setGmeetLink] = useState("");
  const [followUp, setFollowUp] = useState("single");

  useEffect(() => {
    console.log(data.athlete, "athldata");
    if (data) {
      db.collection("athletes")
        .doc(data.athlete)
        .get()
        .then((snap) => {
          if (snap.data().diet) {
            setDiet(snap.data().diet.name);
            setCarbs(snap.data().diet.carbs);
            setFat(snap.data().diet.fat);
            setProtein(snap.data().diet.protein);
            setCalories(snap.data().diet.calories);
            setWeight(snap.data().weight);
            setAtheleteData(snap.data());
          }
        });
    }
  }, [data]);

  const decline = () => {
    if (window.confirm("Confirm decline request")) {
      db.collection("declinedInvites")
        .add(data)
        .then((id) => {
          db.collection("invites")
            .doc(Id)
            .delete()
            .catch(function (error) {
              console.log("Error getting documents: ", error);
            });
          db.collection("coaches")
            .doc(data.coach)
            .update({
              pendingInvites: firebase.firestore.FieldValue.increment(-1),
            });
          history.push("/all-athletes");
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });

      history.push("/all-athletes");
    }

    // alert(
    //   "Wellzap",
    //   "Confirm decline request",
    //   [
    //     {
    //       text: "yes",
    //       onClick: () => {
    //         db.collection("declinedInvites")
    //           .add(data)
    //           .then((id) => {
    //             db.collection("invites")
    //               .doc(location?.state.id)
    //               .delete()
    //               .catch(function (error) {
    //                 console.log("Error getting documents: ", error);
    //               });
    //             navigation.navigate("Athletes");
    //           })
    //           .catch(function (error) {
    //             console.log("Error getting documents: ", error);
    //           });
    //       },
    //     },
    //     {
    //       text: "no",
    //       onClick: () => {},
    //     },
    //   ],
    //   { cancelable: false }
    // );
  };

  const ChangeDiet = (diet) => {
    if (weight && weight != 0) {
      if (diet == "weight maintainance") {
        setCalories(
          String(4 * 1.5 * weight + 4 * 3.5 * weight + 9 * 1 * weight)
        );
        setCarbs(String(3.5 * weight));
        setFat(String(1 * weight));
        setProtein(String(1.5 * weight));
      } else if (diet == "high performance") {
        setCalories(
          String(4 * 1.5 * weight + 4 * 6 * weight + 0.8 * 9 * weight)
        );
        setCarbs(String(6 * weight));
        setFat(String(0.8 * weight));
        setProtein(String(1.5 * weight));
      } else if (diet == "fat loss") {
        setCalories(String(4 * 2 * weight + 4 * 3 * weight + 1 * 9 * weight));
        setCarbs(String(2 * weight));
        setFat(String(1 * weight));
        setProtein(String(2 * weight));
      }
    }
  };

  useEffect(() => {
    if (protein != 0 && carbs != 0 && fat != 0) {
      setCalories(
        String(4 * Number(protein) + 4 * Number(carbs) + 9 * Number(fat))
      );
    }
  }, [fat, protein, carbs]);

  const tokenHandler = async () => {
    await db
      .collection("secrets")
      .doc(userData?.id)
      .get()
      .then(async (snap) => {
        if (!snap.exists) {
          console.log(11222);
          const data = Axios.post(
            "https://wellzapp-server.herokuapp.com/api/gmeet",
            { rURL }
          ).then((res) => {
            if (res?.data?.url) {
              let url = res?.data?.url;
              win = window.open(url, "win1", "width = 500, height = 300");
              var pollTimer = window.setInterval(async function () {
                try {
                  const queryURL = new URL(win.document.URL);

                  var url = queryURL.searchParams.get("code");
                  if (url) {
                    window.clearInterval(pollTimer);
                    let axiosConfig = {
                      headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                      },
                    };

                    let code = url;
                    console.log(code);
                    win?.close();
                    await Axios.post(
                      "https://wellzapp-server.herokuapp.com/api/getToken",
                      {
                        code: code.toString(),
                        rURL,
                      },

                      axiosConfig
                    ).then(async (res) => {
                      console.log((res.data.tokens, "tokens"));

                      if (res.data.success) {
                        db.collection("secrets")
                          .doc(userData?.id)
                          .set({ tokens: res.data.tokens })
                          .then(async () => {
                            setTokens(res.data.tokens);
                            console.log("tokens  saved to db");

                            accept();
                          });
                      } else {
                        console.log("dont get token");
                        return null;
                      }
                    });
                  } else {
                    console.log("dont get code");
                    return null;
                  }
                } catch (error) {
                  console.log(error, "window opening problem");
                  return null;
                }
              }, 1000);
            } else {
              console.log("dont get credentials");
              return null;
            }
          });
        } else {
          let tempTokens = snap.data().tokens;
          console.log(tempTokens, "tempTokens");

          accept();
        }
      });
    // .then(() => {
    //   setTokens(tempTokens)

    // }).then(() => {
    //   getGmeetLink()
    // })
  };
  console.log(new Date(moment(startDate, "DD-MM-YYYY")), "start date");
  const getGmeetLink = async () => {
    if (tokens == undefined) {
      return alert("Try again!");
    }

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    await Axios.post(
      "https://wellzapp-server.herokuapp.com/api/gmeet/getLink",
      {
        tokens: tokens,
        rURL,
        eventdata: {
          eventname: "Folow UP",
          description: "Follow Up",
          videoConference: true,

          date: firebase.firestore.Timestamp.fromDate(new Date(new Date())),
          start: firebase.firestore.Timestamp.fromDate(new Date(new Date())),
        },
      },

      axiosConfig
    )
      .then(async (res) => {
        if (res.data?.success) {
          setGmeetLink(res.data?.event?.data?.hangoutLink);
          console.log(gmeetLink);
          console.log("we got gmeet link");
        } else {
          console.log("cant get meet link");
          return null;
        }
      })
      .then(async () => {
        console.log(gmeetLink);
        console.log("we got gmeet link2");
      });
  };

  console.log(data, "dataq");

  const accept = async () => {
    if (followUp == "multiple" && !followUpFrequency) {
      alert("Select Frequency of follow up");
      return;
    }
    let gLink = "";

    await db
      .collection("coaches")
      .doc(data.coach)
      .get()
      .then((snapshot) => {
        let data = snapshot.data();
        gLink = data?.videoURL;
      })
      .then(() => {
        console.log(gLink);
      });
    // if(!gLink){
    //   alert('try again')
    //   return
    // }
    db.collection("athletes")
      .doc(data.athlete)
      .update({
        listOfCoaches: [data.coach],
        payments: {
          amount: amount,
          frequency: frequency,
        },
        startDate,
        endDate,
        followUpFrequency,
        verified: true,
        diet: {
          name: diet,
          carbs,
          protein,
          fat,
          calories,
        },
      })
      .then((id) => {
        db.collection("coaches")
          .doc(data.coach)
          .update({
            listOfAthletes: firebase.firestore.FieldValue.arrayUnion(
              data.athlete
            ),
          });
        db.collection("invites")
          .doc(Id)
          .delete()
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });
        db.collection("coaches")
          .doc(data.coach)
          .update({
            pendingInvites: firebase.firestore.FieldValue.increment(-1),
          });
        alert("done");
        handleClose();
        handleClose1();
        window.location
          .reload()
          .then(async () => {
            dispatch(
              setUserData({
                data: {
                  ...userData.data,
                  listOfAthletes: [
                    ...userData.data.listOfAthletes,
                    data.athlete,
                  ],
                },
                id: userData?.id,
              })
            );

            var days;
            if (followUpFrequency.value == "Once in a week") {
              days = 7;
            } else if (followUpFrequency.value == "Once in 2 weeks") {
              days = 14;
            } else if (followUpFrequency.value == "Once in a month") {
              days = 30;
            } else {
              days = 90;
            }

            if (followUp === "multiple") {
              var Difference_In_Time =
                moment(endDate, "DD-MM-YYYY").valueOf() -
                moment(startDate, "DD-MM-YYYY").valueOf();
              var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
              console.log(Difference_In_Days, "day diff");

              for (var i = days; i < Difference_In_Days; i = i + days) {
                var temp_date = new Date(
                  moment(startDate, "DD-MM-YYYY").add(i, "days")
                );
                temp_date = new Date(
                  temp_date.getFullYear(),
                  temp_date.getMonth(),
                  temp_date.getDate(),
                  17,
                  0,
                  0,
                  0
                );
                console.log(
                  "temp_date: " + temp_date,
                  ";",
                  "startDate: " + startDate
                );
                await db
                  .collection("events")
                  .doc()
                  .set({
                    name: "Follow up with " + data.name,
                    date: firebase.firestore.Timestamp.fromDate(
                      new Date(temp_date)
                    ),
                    description: "routine followup",
                    athletes: [data.athlete],
                    coachID: userData?.id,
                    showVideoLink: true,
                    videolink: gLink,
                  });
              }
            }
            if (followUp === "single") {
              let temp_date = new Date(moment(startDate, "DD-MM-YYYY"));
              temp_date = new Date(
                temp_date.getFullYear(),
                temp_date.getMonth(),
                temp_date.getDate(),
                17,
                0,
                0,
                0
              );
              await db
                .collection("events")
                .doc()
                .set({
                  name: "Follow up with " + data.name,
                  date: firebase.firestore.Timestamp.fromDate(
                    new Date(temp_date)
                  ),
                  description: "routine followup",
                  athletes: [data.athlete],
                  coachID: userData?.id,
                  showVideoLink: true,
                  videolink: gLink,
                });
            }

            if (frequency == "once in a week") {
              days = 7;
            } else if (frequency == "once in 2 weeks") {
              days = 14;
            } else if (frequency == "once in a month") {
              days = 30;
            } else {
              days = 90;
            }
            Difference_In_Time =
              moment(endDate, "DD-MM-YYYY").valueOf() -
              moment(startDate, "DD-MM-YYYY").valueOf();
            Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            for (var i = days; i < Difference_In_Days; i = i + days) {
              var temp_date = new Date(moment(new Date()).add(i, "days"));
              temp_date = new Date(
                temp_date.getFullYear(),
                temp_date.getMonth(),
                temp_date.getDate(),
                17,
                0,
                0,
                0
              );
              const newCityRef = db.collection("payments").doc();
              const res = await newCityRef.set({
                athleteName: athleteData.name,
                date: firebase.firestore.Timestamp.fromDate(
                  new Date(temp_date)
                ),
                athlete: data.athlete,
                coach: userData?.id,
                amt: amount,
                status: "not paid",
              });
            }
          })
          .then(() => {})
          .then(() => {})
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading.....
      </div>
    );
  }
  return (
    <div
      showsVerticalScrollIndicator={false}
      style={{
        flex: 1,
        backgroundColor: "#F6F6F6",
        padding: 0,
        backgroundColor: "#F6F6F6",
        paddingBottom: 20,
      }}
      contentContainerStyle={{}}
    >
      <div
        style={{
          flexDirection: "row",
          padding: 20,
          paddingBottom: 0,
          display: "flex",
        }}
      >
        {/* <Icon
          name="bars"
          type="font-awesome-5"
          size={24}
          onClick={() => navigation.toggleDrawer()}
        /> */}
      </div>

      <div
        style={{
          padding: 20,
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div onClick={() => history.goBack()}>
            {/* <Icon
            name="chevron-left"
            size={24}
            style={{ marginRight: 100 }}
            type="font-awesome-5"
          /> */}
            <ArrowBackIosRoundedIcon
              style={{ height: 28, width: 28, padding: 5, cursor: "pointer" }}
            />
          </div>
          <img
            src={
              data?.imageUrl
                ? data?.imageUrl
                : "https://firebasestorage.googleapis.com/v0/b/stairs-web-app.appspot.com/o/userImage.jpeg?alt=media&token=b50bf709-1e26-4d1a-8fd6-3689f66322c3"
            }
            style={{
              width: 50,
              height: 50,
              borderRadius: 100,
              alignSelf: "center",
            }}
          />
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: "black",
              marginTop: 10,
              marginLeft: 20,
            }}
          >
            {data.name}
            <br />
            <div
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "black",
                marginTop: 5,
              }}
            >
              Athlete
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          paddingLeft: 20,
          borderRadius: 20,
          marginTop: 20,

          marginHorizontal: 20,
        }}
      >
        <div
          style={{
            fontSize: 20,
            marginBottom: 20,
            color: "black",
          }}
        >
          Mobile Number
        </div>
        <input
          style={{
            backgroundColor: "white",
            borderRadius: 5,
            paddingLeft: 20,
            borderWidth: 1,
            borderColor: "black",
            padding: "5px 10px",
            fontSize: 17,
            width: "97%",
            backgroundColor: "white",
          }}
          value={data.phone}
          placeholder="Phone Numer"
          editable={false}
        />
      </div>

      <div
        style={{
          paddingLeft: 20,
          borderRadius: 20,
          marginTop: 20,
          paddingBottom: 20,
          marginHorizontal: 20,
        }}
      >
        <div
          style={{
            fontSize: 20,
            marginBottom: 20,
            color: "black",
          }}
        >
          Email ID
        </div>
        <input
          style={{
            backgroundColor: "white",
            borderRadius: 5,
            paddingLeft: 20,
            borderWidth: 1,
            borderColor: "black",
            padding: "5px 10px",
            width: "97%",
            fontSize: 17,
            backgroundColor: "white",
          }}
          value={data.email}
          placeholder="Email ID"
          editable={false}
        />
      </div>
      {/* <div
        style={{
          paddingLeft: 20,
          borderRadius: 20,
          marginTop: 20,
          paddingBottom: 20,
          marginHorizontal: 20,
        }}
      >
        <div
          style={{
            fontSize: 20,
            marginBottom: 20,
            color: "black",
          }}
        >
          Follow Up
        </div>

        <div>
          <input
            type="radio"
            value="single"
            name="followUp"
            checked={followUp === "single"}
            onChange={(e) => setFollowUp(e.target.value)}
          />{" "}
          Single
          <input
            type="radio"
            value="multiple"
            name="followUp"
            checked={followUp === "multiple"}
            onChange={(e) => setFollowUp(e.target.value)}
            style={{ marginLeft: 20 }}
          />{" "}
          Multiple
        </div>
       
      </div>
      {followUp === "multiple" ? (
        <div
          style={{
            borderRadius: 20,

            paddingBottom: 20,
            marginHorizontal: 20,
            marginLeft: 20,
          }}
        >
          <div
            style={{
              fontSize: 17,
              marginBottom: 20,

              color: "black",
            }}
          >
            Frequency of Followup
          </div>

         

          <div style={{}}>
            <Dropdown
              options={[
                { label: "Once in a week", value: "Once in a week" },
                { label: "Once in 2 weeks", value: "Once in 2 weeks" },
                { label: "Once in a month", value: "Once in a month" },
                { label: "Once in 3 month", value: "Once in 3 month" },
              ]}
              placeholder="Select frequency of Followup"
              onChange={(item) => {
                setFollowUpFrequency(item);
                console.log(followUpFrequency, "freq");
              }}
            />
          </div>

          <div
            style={{
              textAlign: "center",
              marginTop: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>

          <div
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <div
              style={{ width: "40%", alignItems: "center", display: "flex" }}
            >
              <div
                style={{
                  fontSize: 20,
                  color: "black",
                }}
              >
                Start Date
              </div>
              <DatePicker
                style={{
                  padding: 20,
                }}
                selected={new Date(moment(startDate, "DD-MM-YYYY"))}
                onChange={(date) => setStartDate(date)}
              />
            </div>

            <div
              style={{ width: "40%", alignItems: "center", display: "flex" }}
            >
              <div
                style={{
                  fontSize: 20,

                  color: "black",
                }}
              >
                End Date
              </div>
              <DatePicker
                selected={new Date(moment(endDate, "DD-MM-YYYY"))}
                minDate={new Date(moment(startDate, "DD-MM-YYYY"))}
                onChange={(date) => setEndDate(date)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 20,
            display: "flex",
          }}
        >
          <div style={{ width: "40%", alignItems: "center", display: "flex" }}>
            <div
              style={{
                fontSize: 20,
                color: "black",
              }}
            >
              Select Date
            </div>
            <DatePicker
              style={{
                padding: 20,
              }}
              selected={new Date(moment(startDate, "DD-MM-YYYY"))}
              onChange={(date) => setStartDate(date)}
              minDate={new Date()}
            />
          </div>
        </div>
      )} */}

      <div
        style={{
          marginBottom: 40,
        }}
      >
        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            color: "black",
            marginTop: 20,
            marginLeft: 20,
          }}
        >
          Invite Request
        </div>
        <div
          style={{
            flexDirection: "row",
            marginTop: 20,
            marginLeft: 20,
            display: "flex",
          }}
        >
          <div
            onClick={() => decline()}
            style={{
              backgroundColor: "#808080",
              borderRadius: 10,
              padding: 10,
              width: 120,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <div style={{ color: "var(--secondarycolor)", fontWeight: "bold" }}>
              DECLINE
            </div>
          </div>
          <div
            onClick={() => accept()}
            style={{
              backgroundColor: "var(--primarycolor)",
              borderRadius: 10,
              padding: 10,
              width: 120,
              textAlign: "center",
              marginLeft: 100,
              cursor: "pointer",
            }}
          >
            <div style={{ color: "var(--secondarycolor)", fontWeight: "bold" }}>
              ACCEPT
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invite;
