import React, { useEffect, useState } from "react";
import { auth, db } from "../../utils/firebase";
import { selectUserData, selectUserType } from "../../features/userSlice";
import { useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import OpenDialog from "../../components/dialogbox";
import EditIcon from "@material-ui/icons/Edit";
import "./AdminHome.css";
import AthletesList from "./AthetesList";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import Copy from "./copy.png";
import {
  Dialog,
  DialogContent,
  Grid,
  Divider,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import List from "./List";

function AdminHome() {
  const userType = useSelector(selectUserType);
  const [CoachList, setCoachList] = useState(null);
  const [CoachList_count, setCoachList_count] = useState(30);
  const [AthleteDetails, setAthleteDetails] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [search, setsearch] = useState("");
  const [SearchList, setSearchList] = useState(null);
  const [SearchLoading, SetSearhLoading] = useState(false);
  const [reload, setreload] = useState(false);
  const history = useHistory();
  const [scroll, setScroll] = React.useState("paper");
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const descriptionElementRef = React.useRef(null);
  const [coachData, setCoachData] = useState();
  const [coachId, setCoachId] = useState();
  const [count, setCount] = React.useState(0);
  const [invites, setInvites] = React.useState([]);
  const [HeadCoaches, SetHeadCoaches] = React.useState([]);
  const [Coaches, SetCoaches] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setCoachId("");
  };

  useEffect(() => {
    CoachList?.filter((coach) => {
      return coach.name.toLowerCase().includes(search);
    });
  }, [search]);

  useEffect(() => {
    db.collection("coaches").onSnapshot((snap) => {
      setCoachList(
        snap.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );

      setLoading(false);
    });
  }, [userType, reload]);

  useEffect(() => {
    setSearchList(CoachList);
  }, [CoachList]);

  const getSearchList = async (e) => {
    SetSearhLoading(true);
    if (e.target.value) {
      const names = await CoachList?.filter(({ id, data }) => {
        return data.name.toLowerCase().includes(e.target.value.toLowerCase());
      });

      setSearchList(names);
      SetSearhLoading(false);
    } else {
      setSearchList(CoachList);
      SetSearhLoading(false);
    }
  };

  useEffect(() => {
    db.collection("invites").onSnapshot((snapshot) =>
      setInvites(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          notify: doc.data(),
        }))
      )
    );
  }, []);

  useEffect(() => {
    db.collection("HeadCoaches").onSnapshot((snapshot) =>
      SetHeadCoaches(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          headCoaches: doc.data(),
        }))
      )
    );
  }, []);

  useEffect(() => {
    db.collection("coaches").onSnapshot((snapshot) =>
      SetCoaches(
        snapshot.docs.map((doc) => ({
          idx: doc.id,
          Coaches: doc.data(),
        }))
      )
    );
    
  }, []);
  console.log(Coaches, 'coach')
  if (Loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <div className="CoachList__container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              margin: 20,

              padding: 5,
              display: "flex",
              alignItems: "center",
              border: "1px solid black",
              width: "500px",
            }}
          >
            <input
              style={{
                width: "100%",

                fontSize: 20,
                outline: "none",
                border: "none",
              }}
              onChange={getSearchList}
            />
            <SearchIcon
              style={{
                width: 40,
                height: 40,
              }}
            />
          </div>
          <div
            style={{
              fontSize: 18,
            }}
          >
            {SearchList.length} search results loaded
          </div>
        </div>
        <div
          className="CoachList"
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              width: "30px",
              marginRight: 20,
            }}
          >
            S.no
          </div>
          <div
            style={{
              width: "150px",
              marginRight: 20,
              textAlign: "center",
            }}
          >
            Name
          </div>
          <div
            style={{
              width: "100px",
              marginRight: 20,
              textAlign: "center",
            }}
          >
            Pin
          </div>

          <div style={{ width: 250 }}>Email</div>
          <div
            style={{
              width: 100,
            }}
          >
            Athletes
          </div>
          <div
            style={{
              width: 120,
            }}
          >
            Subcoaches
          </div>

          <div
            style={{
              width: 120,
            }}
          >
            Payments
          </div>
          <div
            style={{
              width: 70,
              textAlign: "center",
            }}
          >
            Edit
          </div>
          <div
            style={{
              width: 70,
              textAlign: "center",
            }}
          >
            Active
          </div>
          <div
            style={{
              width: 90,
              textAlign: "center",
            }}
          >
            Copy Invite
          </div>
          <div
            style={{
              width: 110,
              textAlign: "center",
            }}
          >
            Pending Invites
          </div>
        </div>

        {SearchLoading ? (
          <Spinner />
        ) : (
          SearchList?.map(
            (doc, idx) => (
              // doc.data.email != "test@gmail.com" && (
              <div
                lkey={idx}
                className="CoachList"
                style={{
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "30px",
                    marginRight: 20,
                  }}
                >
                  {idx+1}
                </div>
                <div
                  style={{
                    display: "flex",
                    // alignItems: "center",
                    width: 150,
                    justifyContent: "flex-start",
                  }}
                >
                  <img
                    src={`${doc.data.imageUrl
                      ? doc.data.imageUrl
                      : "https://firebasestorage.googleapis.com/v0/b/stairs-web-app.appspot.com/o/userImage.jpeg?alt=media&token=b50bf709-1e26-4d1a-8fd6-3689f66322c3"
                      }`}
                    width="30"
                    height="30"
                    style={{
                      borderRadius: 20,
                    }}
                  />
                  {doc.data.name}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: 100,
                  }}
                >
                  {doc.data.pin}
                </div>
                <div style={{ width: 250 }} className="email_field">
                  {doc.data.email}
                </div>
                <div
                  style={{
                    width: 100,
                  }}
                >
                  <AthletesList
                    title="view"
                    CoachName={doc.data.name}
                    data={doc?.data?.listOfAthletes}
                  />
                </div>

                <div
                  style={{
                    width: 100,

                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCoachId(doc.id);
                    setOpen2(true);
                  }}
                >
                  {HeadCoaches.map(
                    ({ id, headCoaches }) =>
                      id == doc.id && (
                        <h4
                          style={{
                            fontSize: 15,
                            color: "black",
                            fontWeight: "400",
                            cursor: "pointer",
                          }}
                        >
                          VIEW
                        </h4>
                      )
                  )}
                </div>

                <div
                  style={{
                    width: 120,
                  }}
                >
                  View Payments
                </div>
                <div
                  style={{
                    width: 70,
                    textAlign: "center",
                  }}
                >
                  <EditIcon
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push({
                        pathname: "/edit-coach",
                        state: {
                          Coach: doc.data,
                          id: doc.id,
                        },
                      });
                    }}
                  />
                </div>

                <button
                  style={{
                    width: 70,
                    textAlign: "center",
                    cursor: "pointer",
                    padding: "7px 7px",
                    boxSizing: "border-box",
                    border: "none",
                    boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.3)",
                  }}
                  onClick={() => {
                    var txt;
                    var r =
                      doc.data.active === true
                        ? window.confirm("Are you sure you want to disable")
                        : window.confirm("Are you sure you want to enable");
                    if (r == true) {
                      db.collection("coaches")
                        .doc(doc.id)
                        .update({
                          active:
                            doc.data.active !== undefined
                              ? doc.data.active === true
                                ? false
                                : true
                              : false,
                        })
                        .then((snap) => {
                          console.log(snap);
                          let temp = [...SearchList];
                          temp[idx].data.active = temp[idx].data.active;
                          setreload(!reload);
                        });
                    } else {
                      window.close();
                    }
                  }}
                >
                  {doc.data.active !== undefined
                    ? doc.data.active === true
                      ? "Disable"
                      : "Enable"
                    : "Disable"}
                </button>
                <div>
                  {/* <button
                    style={{
                      width: 70,
                      textAlign: "center",
                      cursor: "pointer",
                      padding: "7px 7px",
                      boxSizing: "border-box",
                      border: "none",
                      boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.3)",
                      marginLeft: 40,
                    }}
                    onClick={() => {
                      db.collection("coaches")
                        .doc(doc.id)
                        .delete()
                        .then(() => {
                          alert("successfully deleted! ");
                        })
                        .catch((error) => {
                          console.log("Error removing document:", error);
                        });
                    }}
                  >
                    Delete
                  </button> */}
                  <div
                    style={{
                      width: 90,
                      textAlign: "center",
                      cursor: "pointer",
                      padding: "7px 7px",

                      border: "none",

                      marginLeft: 60,
                    }}
                    onClick={() => {
                      let text = `Hi Athlete! Join the Stairs app today and get started on your fitness journey! Download the app here, and link with your coach by typing in the Coach ID as ${doc.data.pin}`;
                      navigator.clipboard.writeText(text);
                      alert("Copied the invite to clipboard");
                    }}
                  >
                    <img src={Copy} width="30" />
                  </div>
                  <div
                    style={{
                      width: 110,
                      textAlign: "center",
                      cursor: "pointer",
                      padding: "7px 7px",
                      marginLeft: 20,
                    }}
                    onClick={() => {
                      setOpen(true);
                      setCoachData(doc.data);
                      setCoachId(doc.id);
                    }}
                  >
                    <div
                      style={{
                        width: 30,
                        height: 30,
                        backgroundColor: "red",
                        borderRadius: 24,
                        color: "white",
                      }}
                    >
                      {doc.data.pendingInvites}
                    </div>
                  </div>
                </div>
              </div>
            )
            // )
          )
        )}

        {/* <div>
          <div
            style={{
              display: "flex",
            }}
            onClick={() => {
              if (CoachList.length - CoachList_count > 30) {
                setCoachList_count(CoachList_count + 30);
              } else {
                setCoachList_count(CoachList.length);
              }
            }}
          >
            {CoachList_count}Loaded+ LoadMore
          </div>
        </div> */}
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Athletes</DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <List
                coachData={coachData}
                coachId={coachId}
                handleClose1={handleClose}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          onClose={handleClose2}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">List of coaches</DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div
                className="CoachList"
                style={{
                  display: "flex",
                }}
              >
                <div>Name</div>
                <div style={{ width: 250 }}>Email</div>
              </div>
              {HeadCoaches.map(
                ({ id, headCoaches }) =>
                  id == coachId &&
                  headCoaches.listOfCoaches.map((h) =>
                    Coaches.map(
                      ({ idx, Coaches }) =>
                        idx == h && (
                          <div className="CoachList">
                            <div>{Coaches.name}</div>
                            <div style={{ width: 250 }} className="email_field">
                              {Coaches.email}
                            </div>
                          </div>
                        )
                    )
                  )
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose2} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default AdminHome;
