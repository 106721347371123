import React, { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./AddAthlete.css";
import { Avatar } from "@material-ui/core";
import { db, auth, storage } from "../../utils/firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  login,
  setUserVerified,
  setUserType,
} from "../../features/userSlice";
import { useHistory } from "react-router";
import firebase from "firebase";

function Signup() {
  const [isLoading, setisLoading] = useState(false);
  const [error, seterror] = useState(false);
  const [profPic, setProfPic] = useState();
  const [Id, setId] = useState();
  const [code, setCode] = useState("");
  const [coaches, setCoaches] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const inputFile = useRef(null);

  const phoneRegExp =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid. Not in email address format")
      .required("Email is required"),
    password: Yup.string()
      .min(3, "Password must be 3 characters at minimum")
      .required("Password is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required"),
    height: Yup.string()
      .matches(/^\d+(\.\d{1,2})?$/, "Height is not valid")
      .required("Height is required"),
    weight: Yup.string()
      .matches(/^\d+(\.\d{1,2})?$/, "Weight is not valid")
      .required("Weight is required"),
  });

  const handleProfPic = (e) => {
    if (e.target.files[0]) {
      setProfPic(e.target.files[0]);
    }
  };

  React.useEffect(() => {
    db.collection("coaches").onSnapshot((snapshot) =>
      setCoaches(
        snapshot.docs.map((doc) => ({ id: doc.id, coach: doc.data() }))
      )
    );
    console.log("Hi", coaches);
  }, [code]);

  const sendInvite = (athleteId, name, url, email, phone, coachCode) => {
    db.collection("invites")
      .where("athlete", "==", athleteId)
      .get()
      .then((snap) => {
        if (!snap.empty) {
          snap.forEach(function (doc) {
            db.collection("invites").doc(doc.id).delete();
          });
          db.collection("coaches")
            .where("pin", "==", parseInt(coachCode))
            .get()
            .then(function (querySnapshot) {
              if (querySnapshot.empty) {
                alert("Invalid Coach pin number");
              } else {
                querySnapshot.forEach(function (doc) {
                  db.collection("invites").add({
                    coach: doc.id,
                    athlete: athleteId,
                    name: name,
                    imageUrl: url ? url : '',
                    email: email,
                    phone: phone,
                  });
                  db.collection("coaches")
                    .doc(doc.id)
                    .update({
                      listOfAthletes: firebase.firestore.FieldValue.arrayUnion(athleteId)
                    })


                  db.collection("CoachNotifications")
                    .doc(doc.id)
                    .collection("notifications")
                    .add(
                      {
                        message: `${name} is now one of your athlete! `,
                        seen: false,
                        timestamp:
                          firebase.firestore.FieldValue.serverTimestamp(),
                        athlete_id: athleteId,
                      },
                      { merge: true }
                    );
                });
              }
            })
            .catch(function (error) {
              console.log("Error getting documents: ", error);
            });
        } else {
          db.collection("coaches")
            .where("pin", "==", parseInt(coachCode))
            .get()
            .then(function (querySnapshot) {
              if (querySnapshot.empty) {
                alert("Invalid Coach pin number");
              } else {
                querySnapshot.forEach(function (doc) {
                  db.collection("invites").add({
                    coach: doc.id,
                    athlete: athleteId,
                    name: name,
                    imageUrl: url ? url : '',
                    email: email,
                    phone: phone,
                  });
                  db.collection("coaches")
                    .doc(doc.id)
                    .update({
                      listOfAthletes: firebase.firestore.FieldValue.arrayUnion(athleteId)
                    })

                  db.collection("CoachNotifications")
                    .doc(doc.id)
                    .collection("notifications")
                    .add(
                      {
                        message: `${name} is now one of your athlete!`,
                        seen: false,
                        timestamp:
                          firebase.firestore.FieldValue.serverTimestamp(),
                        athlete_id: athleteId,
                      },
                      { merge: true }
                    );
                });
              }
            })
            .catch(function (error) {
              console.log("Error getting documents: ", error);
            });
        }
      });
  };
  return (
    <div className="signup">
      <div className="signup__header">
        <img
          className="leftarrow"
          src="/assets/left_arrow.png"
          alt=""
          onClick={() => {
            history.goBack();
          }}
        />
        <h1>Onboard a new Athlete</h1>
      </div>
      <input type="file" onChange={handleProfPic} ref={inputFile} hidden />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        {profPic ? (
          <img
            src={URL.createObjectURL(profPic)}
            style={{ width: 50, height: 50 }}
          />
        ) : (
          <Avatar
            style={{ cursor: "pointer" }}
            onClick={() => inputFile.current.click()}
          />
        )}
        <div
          onClick={() => inputFile.current.click()}
          style={{
            backgroundColor: "var(--primarycolor)",
            color: "var(--secondarycolor)",
            textAlign: "center",
            marginLeft: 20,
            borderRadius: 10,
            cursor: "pointer",
            padding: "5px 20px",
          }}
        >
          Upload Picture
        </div>
      </div>

      <Formik
        initialValues={{
          email: "",
          password: "",
          password2: "",
          name: "",
          phone: "",
          address: "",
          date: "",
          gender: "",
          userType: "",
          height: 0,
          weight: 0,
          coachCode: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={async (values, { setSubmitting }) => {
          // alert("Form is validated! Submitting the form...");
          // console.log(values);
          setCode(values.coachCode);
          let temporary = [];
          coaches.map(
            ({ id, coach }) =>
              coach.pin == values.coachCode && temporary.push(id)
          );

          let url;
          temporary.length == 0
            ? alert("Invalid Coach Code")
            : auth
              .createUserWithEmailAndPassword(values.email, values.password)
              .then(async (auth) => {
                const storageRef = storage.ref();
                const fileRef = storageRef.child(
                  `/images/${values.email}/profpic`
                );
                await fileRef
                  .put(profPic)
                  .then(async () => {
                    if (profPic) {
                      url = await fileRef.getDownloadURL();
                    }
                  })

                  .then(async () => {
                    console.log(1);

                    dispatch(login(auth.user.email));
                    dispatch(setUserType("athlete"));
                    dispatch(setUserVerified(false));

                    await db
                      .collection("athletes")
                      .add({
                        name: values.name,
                        phone: values.phone,
                        email: values.email.toLowerCase(),
                        gender: values.gender,
                        dob: values.date,
                        address: values.address,
                        imageUrl: url ? url : "",
                        sports: [],
                        verified: true,
                        height: values.height,
                        weight: values.weight,
                        completedWorkouts: 0,
                        averageWorkoutTime: 0,
                        goalsMet: 0,
                        diet: {
                          name: "weight maintainance",
                          carbs: 3.5 * values.weight,
                          protein: 1.5 * values.weight,
                          fat: 1 * values.weight,
                          calories:
                            4 * 3.5 * values.weight +
                            4 * 1.5 * values.weight +
                            9 * 1 * values.weight,
                        },
                        createdAt:
                          firebase.firestore.FieldValue.serverTimestamp(),
                        listOfAthletes: [],
                        listOfCoaches: temporary,
                      })
                      .then((doc) => {
                        coaches.forEach(coach => {
                          if (parseInt(values.coachCode) == coach.coach.pin) {
                            console.log('coachidentified')
                            db.collection("coaches")
                              .doc(coach.id)
                              .update({
                                listOfAthletes: firebase.firestore.FieldValue.arrayUnion(doc.id)
                              })
                          }
                        })


                        alert("Success");
                      })
                      .catch((e) => {
                        console.log(e);
                        setSubmitting(false);
                      });
                    setisLoading(false);
                  });
              })

              .catch((e) => {
                setisLoading(false);
                seterror(true);
                console.log(e);
                alert(e.message);
                setSubmitting(false);
              });
        }}
      >
        {({ touched, errors, isSubmitting, values }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="Name">Name</label>
              <Field
                type="name"
                name="name"
                placeholder="Enter Name"
                className="form-control input-field"
              />
            </div>

            <label htmlFor="Name">Gender</label>

            <div className="form-radiogroup">
              <Field
                name="decision"
                render={({ field }) => (
                  <>
                    <div className="radio-item">
                      <input
                        {...field}
                        id="Male"
                        value="Male"
                        // checked={field.value === "Male"}
                        name="gender"
                        type="radio"
                      />
                      <label htmlFor="Male">Male</label>
                    </div>

                    <div className="radio-item">
                      <input
                        {...field}
                        id="Female"
                        value="Female"
                        // checked={field.value === "Female"}
                        type="radio"
                        name="gender"
                      />
                      <label htmlFor="Female">Female</label>
                    </div>
                  </>
                )}
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <Field
                type="phone"
                name="phone"
                placeholder="Enter Phone Number"
                className="form-control input-field"
              />
              {touched.phone && (
                <p className="invalid-feedback">{errors.phone}</p>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="address">Address</label>
              <Field
                type="address"
                name="address"
                placeholder="Enter Address"
                className="form-control input-field"
              />
            </div>

            <div className="form-group">
              <label htmlFor="height">Height (cm)</label>
              <Field
                type="height"
                name="height"
                placeholder="Enter Height"
                className="form-control input-field"
              />
              {touched.height && (
                <p className="invalid-feedback">{errors.height}</p>
              )}

              <label htmlFor="weight">Weight (kg)</label>
              <Field
                type="weight"
                name="weight"
                placeholder="Enter Weight"
                className="form-control input-field"
              />
              {touched.weight && (
                <p className="invalid-feedback">{errors.weight}</p>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Field
                type="email"
                name="email"
                placeholder="Enter email"
                className={`form-control input-field ${touched.email && errors.email ? "is-invalid" : ""
                  }`}
              />
              <ErrorMessage
                component="div"
                name="email"
                className="invalid-feedback"
              >
                {(msg) => <p className="invalid-feedback">{msg}</p>}
              </ErrorMessage>
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field
                type="password"
                name="password"
                placeholder="Enter password"
                className="form-control input-field"
              />
              {touched.password && (
                <p className="invalid-feedback">{errors.password}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="coachCode">Link Coach</label>
              <Field
                type="text"
                name="coachCode"
                placeholder="Enter Coach code"
                className={`form-control input-field ${touched.coachCode && errors.coachCode ? "is-invalid" : ""
                  }`}
              />
              <ErrorMessage
                component="div"
                name="email"
                className="invalid-feedback"
              >
                {(msg) => <p className="invalid-feedback">{msg}</p>}
              </ErrorMessage>
            </div>
            <button
              type="submit"
              className="signup__button"
              disabled={isSubmitting}
              onClick={() => {
                console.log(values);
              }}
            >
              {isSubmitting ? "Please wait..." : "Sign Up"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Signup;
