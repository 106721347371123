import React, { useEffect, useState } from "react";
import { auth, db } from "../../utils/firebase";
import { selectUserData, selectUserType } from "../../features/userSlice";
import { useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import OpenDialog from "../../components/dialogbox";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/AddBox";
import "./CoachAthlete.css";
import {
  Dialog,
  DialogContent,
  Grid,
  Divider,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
// import AthletesList from "./AthetesList";
import SearchIcon from "@material-ui/icons/Search";
import { useLocation } from "react-router-dom";
import PaymentsList from "./Payments";
import Modal from "react-awesome-modal";
import firebase from "firebase";
function AllAthletes() {
  const userType = useSelector(selectUserType);
  const [CoachList, setCoachList] = useState(null);
  const [CoachList_count, setCoachList_count] = useState(30);
  const [AthleteDetails, setAthleteDetails] = useState(null);
  const [AthleteList, setAthleteList] = useState(null);
  const [Loading, setLoading] = useState(true);
  const descriptionElementRef = React.useRef(null);

  const [searchValue, setsearchValue] = useState("");
  const [SearchList, setSearchList] = useState(null);
  const [SearchLoading, SetSearhLoading] = useState(false);
  const [reload, setreload] = useState(false);
  const [Modal, setModal] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [ModalData, setModalData] = React.useState([]);
  const [ModalData2, setModalData2] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [athleteId, setAthleteId] = React.useState("");
  const [athleteCoaches, setAthleteCoaches] = React.useState([]);
  const [CoachDetails, setCoachDetails] = React.useState();
  const [coaches, setCoaches] = React.useState([]);

  const location = useLocation();


  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  // useEffect(() => {
  //   AthleteList?.filter((coach) => {
  //     return coach.name.toLowerCase().includes(search);
  //   });
  // }, [search]);

  useEffect(async () => {
    if (userType) {
      let temp = [];
      let temp1 = [];

      await db.collection("coaches")
        .get()
        .then((snap) => {
          snap.docs.map((doc) => {
            let appObj = { ...doc.data(), ["id"]: doc.id };
            temp1.push(appObj);
          });
          setCoachList(temp1);
        })
        .then(async () => {
          await db.collection("athletes")
            .orderBy("name", "asc")
            .get()
            .then((snapshot) => {
              snapshot.docs.forEach(async (athlete) => {
                let currentID = athlete.id;
                let appObj = { ...athlete.data(), ["id"]: currentID };
                // console.log(athlete.data()?.listOfCoaches);
                if (athlete.data()?.listOfCoaches?.length > 0) {
                  let data = await CoachList?.filter((tmp) => {
                    if (athlete.data()?.listOfCoaches[0] === tmp.id) {
                      appObj["coach"] = tmp;
                      temp.push(appObj);
                    }
                    return athlete.data()?.listOfCoaches[0] === tmp.id;
                  });
                } else {
                  temp.push(appObj);
                }
              });
              setAthleteList(temp);
              setLoading(false);
            });
        });
      //setAthleteList(location.state?.Athletes);
    } else {
      await db.collection("coaches")
        .get()
        .then((snap) => {
          setCoachList(
            snap.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          );

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userType, AthleteList]);

  useEffect(() => {
    if (!searchValue) {

      setSearchList(AthleteList);
    }
    // let e = { target: { value: '' } }
    // getSearchList(e)

  }, [AthleteList]);

  // const getData = async () => {
  //   const ref = db.collection("athletes").doc(athleteId);
  //   const doc = await ref.get();
  //   setAthleteCoaches(doc.data().listOfCoaches);
  //   console.log("Coaches", athleteCoaches);
  // };
  const getSearchList = async (e) => {
    setsearchValue(e.target.value)
    SetSearhLoading(true);
    if (e.target.value) {
      const names = await AthleteList?.filter((coach) => {
        return coach?.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });

      setSearchList(names);
      SetSearhLoading(false);
    } else {
      setSearchList(AthleteList);
      SetSearhLoading(false);
    }
  };
  const getSearchList2 = async (e) => {
    SetSearhLoading(true);

    const names = await AthleteList?.filter((coach) => {
      return coach.data?.listofCoaches && coach.data?.listofCoaches?.length > 0;
    });

    setSearchList(names);
    SetSearhLoading(false);
  };

  useEffect(() => {
    db.collection("coaches").onSnapshot((snapshot) =>
      setCoaches(
        snapshot.docs.map((doc) => ({ id: doc.id, coaches: doc.data() }))
      )
    );
  }, []);

  if (Loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <div className="CoachList__container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              margin: 20,

              padding: 5,
              display: "flex",
              alignItems: "center",
              border: "1px solid black",
              width: "500px",
            }}
          >
            <input
              style={{
                width: "100%",

                fontSize: 20,
                outline: "none",
                border: "none",
              }}
              onChange={getSearchList}
            />
            <SearchIcon
              style={{
                width: 40,
                height: 40,
              }}
            />
          </div>
          {/* <div
            style={{
              fontSize: 18,
            }}
            onClick={getSearchList2}
          ></div> */}
          <div
            style={{
              fontSize: 18,
            }}
          >
            {SearchList?.length} search results loaded
          </div>
        </div>
        <div
          className="CoachList"
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              width: "30px",
              marginRight: 20,
            }}
          >
            S.no
          </div>
          <div>Name</div>
          <div style={{ width: 250 }}>Email</div>
          <div style={{ width: 150 }}>Coach</div>

          {/* <div
            style={{
              width: 120,
            }}
          >
            Payments
          </div>
          <div
            style={{
              width: 70,
              textAlign: "center",
            }}
          >
            Edit
          </div> */}
          <div
            style={{
              width: 70,
              textAlign: "center",
            }}
          >
            Active
          </div>
        </div>

        {SearchLoading ? (
          <Spinner />
        ) : (
          SearchList?.map((athlete, idx) => (
            <div
              lkey={idx}
              className="CoachList"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "30px",
                  marginRight: 20,
                }}
              >
                {idx + 1}
              </div>
              <div
                style={{
                  marginLeft: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    // alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  {" "}
                  <img
                    src={
                      athlete?.imageUrl
                        ? athlete?.imageUrl
                        : "https://firebasestorage.googleapis.com/v0/b/stairs-web-app.appspot.com/o/userImage.jpeg?alt=media&token=b50bf709-1e26-4d1a-8fd6-3689f66322c3"
                    }
                    width="30"
                    height="30"
                    style={{
                      borderRadius: 20,
                    }}
                  />{" "}
                  {athlete?.name}
                </div>
              </div>

              <div style={{ width: 250 }} className="email_field">
                {athlete?.email}
              </div>

              {/* <div style={{ width: 150 }}>VIEW</div> */}
              {/* <div
                style={{ width: 150 }}
                onClick={() => {
                  // setModal(!Modal);
                  let data = [];
                  let ids = [];

                  if (
                    athlete?.listOfCoaches &&
                    athlete?.listOfCoaches?.length > 0
                  ) {
                    db.collection("HeadCoaches")
                      .where(
                        "listOfCoaches",
                        "array-contains",
                        athlete.listOfCoaches[0]
                      )
                      .get()
                      .then((snapshot) => {
                        snapshot.docs.map((snap) => {
                          ids.push(snap.data().CoachId);
                        });
                        // let tmp = [...ModalData];

                        //setModalData(data);
                      })
                      .then(() => {
                        db.collection("coaches")

                          .get()
                          .then((snap) => {
                            snap.docs.map((doc) => {
                              if (ids.includes(doc.id)) {
                                let temp = {};
                                temp = { ...doc.data(), ["id"]: doc.id };
                                data.push(temp);
                                if (
                                  doc
                                    .data()
                                    ?.listOfAthletes?.includes(athlete.id)
                                ) {
                                  setModalData2(doc.data());
                                }
                              }
                            });

                            setModalData(data);
                          });
                      });
                    setModal(true);
                  } else {
                  }
                }}
              >
                {athlete?.listOfCoaches && athlete?.listOfCoaches?.length > 0
                  ? athlete?.listOfCoaches[0]
                  : "null"}
              </div> */}
              <div style={{ width: 150, position: "relative" }}>
                <h1
                  style={{
                    fontSize: 16,
                    color: "black",
                    fontWeight: "400",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCoachDetails(athlete?.listOfCoaches);
                    setAthleteId(athlete.id);
                    setOpen2(true);
                  }}
                >
                  VIEW
                </h1>
                <AddIcon
                  style={{
                    cursor: "pointer",
                    marginLeft: 5,
                  }}
                  onClick={() => {
                    setAthleteId(athlete.id);
                    setOpen(true);
                  }}
                />
                {/* {athlete?.coach ? (
                      <div
                        onClick={() => {
                          db.collection("coaches")
                            .doc(athlete?.coach?.id)
                            .update({
                              listofAthletes:
                                firebase.firestore.FieldValue.arrayRemove(
                                  athlete.id
                                ),
                            });
                        }}
                        style={{
                          position: "absolute",
                          left: 0,
                        }}
                      ></div>
                    ) : (
                      <div
                        onClick={() => {}}
                        style={{
                          position: "absolute",
                          left: 0,
                        }}
                      ></div>
                    )} */}
              </div>

              {/* <div
                style={{
                  width: 120,
                  cursor: "pointer",
                }}
              >
                <PaymentsList
                  title="view"
                  CoachName={athlete.name}
                  data={athlete.id}
                />
              </div>
              <div
                style={{
                  width: 70,
                  textAlign: "center",
                }}
              >
                <EditIcon />
              </div> */}

              <button
                style={{
                  width: 70,
                  textAlign: "center",
                  cursor: "pointer",
                  padding: "7px 7px",
                  boxSizing: "border-box",
                  border: "none",
                  boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.3)",
                }}
                onClick={() => {
                  db.collection("athletes")
                    .athlete(athlete.id)
                    .update({
                      active:
                        athlete?.active !== undefined
                          ? athlete?.active === true
                            ? false
                            : true
                          : false,
                    })
                    .then((snap) => {
                      let temp = [...SearchList];
                      temp[idx].active = temp[idx].active;
                      setreload(!reload);
                    });
                }}
              >
                {athlete?.active !== undefined
                  ? athlete?.active === true
                    ? "Disable"
                    : "Enable"
                  : "Disable"}
              </button>
              <button
                style={{
                  marginLeft: 20,
                  width: 70,
                  textAlign: "center",
                  cursor: "pointer",
                  padding: "7px 7px",
                  boxSizing: "border-box",
                  border: "none",
                  boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.3)",
                }}
                onClick={() => {
                  var r = window.confirm("Are you sure you want to delete?");
                  if (r == true) {
                    db.collection("athletes")
                      .doc(athlete.id)
                      .delete()

                      .catch((error) => {
                        console.log("Error removing document:", error);
                      });
                  } else {
                    window.close();
                  }
                }}
              >
                Delete
              </button>
            </div>
          ))
        )}

        {/* <div>
          <div
            style={{
              display: "flex",
            }}
            onClick={() => {
              if (CoachList.length - CoachList_count > 30) {
                setCoachList_count(CoachList_count + 30);
              } else {
                setCoachList_count(CoachList.length);
              }
            }}
          >
            {CoachList_count}Loaded+ LoadMore
          </div>
        </div> */}

        <div>{ }</div>
      </div>
      {/* <Modal
        visible={Modal}
        width="450px"
        height="300"
        effect="fadeInUp"
        onClickaway={() => setModal(false)}
      >
        <div className="createWorkout__modal"></div>
      </Modal> */}
      {/* <Dialog
        open={Modal}
        onClose={() => setModal(false)}
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ width: 1000, height: 550 }}></div>
        </DialogContent>
      </Dialog> */}
      {/* <Dialog
        open={Modal}
        onClose={() => {
          setModal(false);
        }}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{"modaldata"}</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div
              className="CoachList"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "30px",
                  marginRight: 20,
                }}
              >
                S.no
              </div>
              <div>Name</div>
              <div style={{ width: 250 }}>Email</div>
            </div>

            <div
              className="CoachList"
              style={{
                display: "flex",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  width: "30px",
                  marginRight: 20,
                }}
              >
                s
              </div>
              <div>name</div>
              <div style={{ width: 250 }} className="email_field">
                'email'
              </div>
              <div>Athletes</div>
            </div>
          </DialogContentText>
        </DialogContent> */}
      {/* <DialogActions>
          <Button onClick={} color="primary">
            Close
          </Button>
        </DialogActions> */}
      {/* </Dialog> */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">List of coaches</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {CoachList.map((coach) => (
              <div>
                <h1
                  style={{
                    fontSize: 30,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    db.collection("athletes")
                      .doc(athleteId)
                      .update({
                        listOfCoaches: firebase.firestore.FieldValue.arrayUnion(
                          coach.id
                        ),
                      });
                    db.collection("coaches")
                      .doc(coach.id)
                      .update({
                        listOfAthletes:
                          firebase.firestore.FieldValue.arrayUnion(athleteId),
                      });
                    handleClose();
                    setAthleteId("");
                  }}
                >
                  {coach.name}
                </h1>
              </div>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        onClose={handleClose2}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">List of coaches</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div
              className="CoachList"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "30px",
                  marginRight: 20,
                }}
              >
                S.no
              </div>
              <div>Name</div>
              <div style={{ width: 250 }}>Email</div>
              <div
                style={{
                  marginLeft: 40,
                }}
              >
                Delete
              </div>
            </div>
            {CoachDetails &&
              CoachDetails.map((item, idx) =>
                coaches.map(
                  ({ id, coaches }) =>
                    id == item && (
                      <div
                        key={idx}
                        className="CoachList"
                        style={{
                          display: "flex",
                          cursor: "pointer",
                        }}
                      // onClick={() => {
                      //   history.push({
                      //     pathname: "/athletes",
                      //     state: {
                      //       Athletes: data,
                      //     },
                      //   });
                      // }}
                      >
                        <div
                          style={{
                            width: "30px",
                            marginRight: 20,
                          }}
                        >
                          {idx + 1}
                        </div>

                        <div>{coaches.name}</div>
                        <div style={{ width: 250 }} className="email_field">
                          {coaches.email}
                        </div>
                        <button
                          style={{
                            width: 70,
                            textAlign: "center",
                            cursor: "pointer",
                            padding: "7px 7px",
                            boxSizing: "border-box",
                            border: "none",
                            boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.3)",
                            marginLeft: 40,
                          }}
                          onClick={() => {
                            var r = window.confirm(
                              "Are you sure you want to delete?"
                            );
                            if (r == true) {
                              db.collection("coaches")
                                .doc(id)
                                .update({
                                  listOfAthletes:
                                    firebase.firestore.FieldValue.arrayRemove(
                                      athleteId
                                    ),
                                });
                              db.collection("athletes")
                                .doc(athleteId)
                                .update({
                                  listOfCoaches:
                                    firebase.firestore.FieldValue.arrayRemove(
                                      id
                                    ),
                                });
                              // alert("Deleted");
                              handleClose2();
                            } else {
                              window.close();
                            }
                          }}
                        >
                          Delete
                        </button>
                        {/* <div>Athletes</div> */}
                      </div>
                    )
                )
              )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AllAthletes;
