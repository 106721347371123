import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// import Notification from "../../Components/Notifications/Notification";
import {
  selectUser,
  selectUserData,
  selectUserType,
  setUserData,
} from "../../features/userSlice";
import { db } from "../../utils/firebase";

import Invite from "./Invite";

// import Header from "../../Components/Header/Header";
// import "./Profile.css";
import { useHistory, useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

import {
  Dialog,
  DialogContent,
  Grid,
  Divider,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

const List = ({ coachData, coachId, handleClose1 }) => {
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);
  const userType = useSelector(selectUserType);
  const dispatch = useDispatch();
  const [athleteDetails, setAthleteDetails] = useState([]);
  const [scroll, setScroll] = React.useState("paper");
  const [open, setOpen] = React.useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const descriptionElementRef = React.useRef(null);
  const [search, setSearch] = useState("");
  const [athletes, setAthletes] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState();
  const [Id, setId] = useState();

  const [listOfAthletes, setListOfAthletes] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    db.collection("coaches")
      .where("email", "==", coachData.email)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          dispatch(
            setUserData({
              id: doc.id,
              data: doc.data(),
            })
          );
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });

    if (coachData) {
      const data = [];
      db.collection("athletes")
        .orderBy("name", "asc")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((athlete) => {
            if (userData?.data?.listOfAthletes?.includes(athlete.id)) {
              let currentID = athlete.id;
              let appObj = { ...athlete.data(), ["id"]: currentID };
              data.push(appObj);
            }
          });
          setAthleteDetails(data);
        });
    }
  }, [coachData, location]);

  useEffect(() => {
    var temp = [];
    const data = [];

    if (coachId) {
      db.collection("invites")
        .where("coach", "==", coachId)
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((item) => {
            let currentID = item.id;
            let appObj = { ...item.data(), ["id"]: currentID };
            console.log(appObj);
            data.push(appObj);
            temp.push(
              <div
                key={appObj.name}
                activeOpacity={0.5}
                onClick={() => {
                  setOpen(true);
                  setData(item.data());
                  setId(item.id);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <div
                  className="athlete__item"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        appObj?.imageUrl
                          ? appObj?.imageUrl
                          : "https://firebasestorage.googleapis.com/v0/b/stairs-web-app.appspot.com/o/userImage.jpeg?alt=media&token=b50bf709-1e26-4d1a-8fd6-3689f66322c3"
                      }
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 100,
                        alignSelf: "center",
                      }}
                    />
                    <div style={{ marginLeft: 30 }}>
                      <div
                        style={{
                          fontSize: 15,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {appObj?.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
          setAthleteDetails(data);
          setAthletes(temp);
        });
    }
  }, [coachData, location]);

  return (
    <div>
      <div
        className="coachProfile"
        id="allath"
        style={{ minHeight: "99.99vh" }}
      >
        <div className="allAthletes__info">
          <div
            onClick={() => history.push("/")}
            style={{ marginTop: 20, display: "flex", alignItems: "center" }}
          >
            {/* <ArrowBackIosRoundedIcon
              style={{ height: 18, width: 18, padding: 5, cursor: "pointer" }}
            /> */}
            <Typography variant="h6" style={{ fontSize: 25, marginLeft: 5 }}>
              Pending Invites
            </Typography>
          </div>
        </div>
        <div className="coachProfile__container">
          <div className="coachProfile__leftContainer">
            {athletes?.length == 0 && (
              <h1
                style={{
                  fontSize: 15,
                  backgroundColor: "white",
                  textAlign: "center",
                  height: 90,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 5,
                  width: "100%",
                }}
              >
                There are no pending requests now
              </h1>
            )}
            {athletes}
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title"></DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Invite
              Id={Id}
              data1={data}
              handleClose={handleClose}
              handleClose1={handleClose1}
              coachId={coachId}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default List;
