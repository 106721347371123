import firebase from "firebase";

if (!firebase.apps.length) {
  const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDlHXSMfzmKjPJGcDJYwR_QhzBZbR3nmH8",
    authDomain: "stairs-prod-7aa7b.firebaseapp.com",
    projectId: "stairs-prod-7aa7b",
    storageBucket: "stairs-prod-7aa7b.appspot.com",
    messagingSenderId: "729539314855",
    appId: "1:729539314855:web:2ea8b0a59f233148d84782",
  });
} else {
  firebase.app(); // if already initialized, use that one
}

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };
