import React from "react";
import { useHistory } from "react-router";
import "./TopBar.css";

function BarComponent({ logo, name, path }) {
  const history = useHistory();

  const curr_path = window.location.pathname.split("/")[1];
  return (
    <div
      className="drawer__container"
      style={{
        backgroundColor: curr_path == path && "#ff2600",
      }}
      onClick={() => history.push(`/${path}`)}
    >
      <div
        style={{
          padding: 30,
        }}
      >
        {name}
      </div>
    </div>
  );
}

export default BarComponent;
