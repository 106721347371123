import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { db } from "../../utils/firebase";
import { useHistory } from "react-router-dom";

export default function AthletesList({ title, data, CoachName }) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [dialog_data, setdialog_data] = React.useState(data ? data : null);
  const [name, setname] = React.useState(title);
  const history = useHistory();
  const [AthleteDetails, setAthleteDetails] = React.useState(null);
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
    let temp = [];

    db.collection("athletes")
      .orderBy("name", "asc")
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach((athlete) => {
          if (dialog_data?.includes(athlete.id)) {
            let currentID = athlete.id;
            let appObj = { ...athlete.data(), ["id"]: currentID };
            temp.push(appObj);
          }
        });
        setAthleteDetails(temp);
        console.log(temp);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button onClick={handleClickOpen("body")}>{name}</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {CoachName && CoachName}
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div
              className="CoachList"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "30px",
                  marginRight: 20,
                }}
              >
                S.no
              </div>
              <div>Name</div>
              <div style={{ width: 250 }}>Email</div>
            </div>
            {AthleteDetails &&
              AthleteDetails.map((item, idx) => (
                <div
                  key={idx}
                  className="CoachList"
                  style={{
                    display: "flex",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: "/athletes",
                      state: {
                        Athletes: data,
                      },
                    });
                  }}
                >
                  <div
                    style={{
                      width: "30px",
                      marginRight: 20,
                    }}
                  >
                    {idx + 1}
                  </div>
                  {console.log(item)}
                  <div>{item.name}</div>
                  <div style={{ width: 250 }} className="email_field">
                    {item.email}
                  </div>
                  {/* <div>Athletes</div> */}
                </div>
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
