import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { auth, db } from "../../utils/firebase";
import { selectUserData, selectUserType } from "../../features/userSlice";
import { useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

import { useHistory } from "react-router-dom";

const EditCoach = () => {
  const location = useLocation();
  const [gender, setGender] = React.useState(location.state.Coach.gender);
  const [type, setType] = React.useState(location.state.Coach.CoachType);
  const [name, setName] = useState(location.state.Coach.name);
  const [phone, setphone] = useState(location.state.Coach.phone);
  const [email, setemail] = useState(location.state.Coach.email);
  const [address, setaddress] = useState(location.state.Coach.address);
  const history = useHistory();

  const saveprofile = () => {
    if (
      name == location.state.Coach.name &&
      phone == location.state.Coach.phone &&
      address == location.state.Coach.address &&
      gender == location.state.Coach.gender &&
      type == location.state.Coach.CoachType
    ) {
      alert("No Changes Done");
    } else {
      db.collection("coaches")
        .doc(location.state.id)
        .update({
          name: name,
          phone: phone,
          gender: gender,
          address: address,
          CoachType: type,
        })
        .then(alert("Saved Successfully"))
        .catch((e) => console.log(e));
    }
  };

  return (
    <div
      style={{
        // justifyContent: "center",
        // // textAlign: "center",
        // alignItems: "center",
        width: "300px",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <ArrowBackIosRoundedIcon
          onClick={() => history.goBack()}
          style={{
            height: 28,
            width: 28,
            padding: 5,
            cursor: "pointer",
            marginRight: 20,
          }}
        />
        <h1>Edit Details</h1>
      </div>

      <div>
        <div style={{}}>
          <label>Name</label>
          <input
            style={{
              borderRadius: "0.3rem",
              border: "0.12rem solid grey",
              height: "1.5rem",
              margin: "0.3rem 0",
              width: "100%",
            }}
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <label>Gender</label>
        </div>

        <div style={{ marginTop: 10 }}>
          <input
            type="radio"
            value="Male"
            name="gender"
            checked={gender === "Male"}
            onChange={(e) => setGender(e.target.value)}
          />
          <label>Male</label>
          <input
            type="radio"
            value="Female"
            name="gender"
            checked={gender === "Female"}
            onChange={(e) => setGender(e.target.value)}
          />
          <label>Female</label>
        </div>
        <div style={{ marginTop: 15 }}>
          <label>Coach Type</label>
        </div>
        <div
          style={{ marginTop: 10, display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              type="radio"
              value="Strength and Conditioning"
              name="type"
              checked={type === "Strength and Conditioning"}
              onChange={(e) => setType(e.target.value)}
            />
            <label>Strength and Conditioning</label>
          </div>

          <div
            style={{
              display: "flex",
            }}
          >
            <input
              type="radio"
              value="Physiotherapist"
              name="type"
              checked={type === "Physiotherapist"}
              onChange={(e) => setType(e.target.value)}
            />
            <label>Physiotherapist</label>
          </div>

          <div
            style={{
              display: "flex",
            }}
          >
            <input
              type="radio"
              value="Rehab"
              name="type"
              checked={type === "Rehab"}
              onChange={(e) => setType(e.target.value)}
            />
            <label>Rehab</label>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        <label>Phone</label>
        <input
          style={{
            borderRadius: "0.3rem",
            border: "0.12rem solid grey",
            height: "1.5rem",
            margin: "0.3rem 0",
            width: "100%",
          }}
          onChange={(e) => {
            setphone(e.target.value);
          }}
          value={phone}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <label>Address</label>
        <input
          style={{
            borderRadius: "0.3rem",
            border: "0.12rem solid grey",
            height: "1.5rem",
            margin: "0.3rem 0",
            width: "100%",
          }}
          onChange={(e) => {
            setaddress(e.target.value);
          }}
          value={address}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <label>Email</label>
        <input
          style={{
            borderRadius: "0.3rem",
            border: "0.12rem solid grey",
            height: "1.5rem",
            margin: "0.3rem 0",
            width: "100%",
          }}
          // onChange={(e) => {
          //   setemail(e.target.value);
          // }}
          value={email}
          readonly
        />
      </div>

      <button
        type="submit"
        className="signup__button"
        onClick={() => saveprofile()}
      >
        Save
      </button>
    </div>
  );
};

export default EditCoach;
