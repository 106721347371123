import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import AthletesList from "../Home/AthetesList";
import "./workouts.css";
import formatDate from "../../functions/formatDate";
function Workouts() {
  const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));
  const [coaches, setCoaches] = useState([]);
  const [showAssignedWkts, setShowAssignedWkts] = useState(false);
  const [showAssignedWktsDetails, setShowAssignedWktsDetails] = useState(false);
  const [allAssignedWkts, setAllAssignedWkts] = useState([]);
  const [selectedCoachId, setSelectedCoachId] = useState("");
  const [workout, setWorkout] = useState(null);

  useEffect(() => {
    console.log("------>", selectedDate);
  }, []);
  const dateHandler = (e) => {
    const dateArray = e.target.value.split("-");
    // setSelectedDate(new Date(dateArray[0], dateArray[1] - 1, dateArray[2]))
    setSelectedDate(e.target.value);
    console.log("date", dateArray);
  };
  const getAllCoaches = async () => {
    let tempData = [];
    await db
      .collection("coaches")
      .get()
      .then((snap) => {
        snap.docs.map((doc) => {
          tempData.push({ ...doc.data(), id: doc.id });
        });
        setCoaches(tempData);
      });
  };

  const getAllAssignedWorkouts = () => {
    let data = [];
    db.collection("CoachWorkouts")
      .where("saved", "==", false)
      .onSnapshot((snapshot) => {
        data = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        setAllAssignedWkts(data);
      });
  };

  useEffect(() => {
    getAllCoaches();
    getAllAssignedWorkouts();
  }, []);
  return (
    <div className="workouts">
      <label>
        Select Date:
        <input
          style={{ marginLeft: 20 }}
          type="date"
          value={selectedDate}
          onChange={dateHandler}
        />
      </label>
      <div>
        <div
          className="CoachList"
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              width: "30px",
              marginRight: 20,
            }}
          >
            S.no
          </div>
          <div>Coach</div>
        </div>
        {coaches.map((coach, index) => (
          <>
            <div
              className="CoachList"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "30px",
                  marginRight: 20,
                }}
              >
                {index + 1}
              </div>
              <div>{coach.name}</div>
              <button
                onClick={() => {
                  setShowAssignedWkts(true);
                  setSelectedCoachId(coach.id);
                }}
              >
                Workouts{" "}
              </button>
            </div>
          </>
        ))}
      </div>
      <Dialog
        open={showAssignedWkts}
        onClose={() => setShowAssignedWkts(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Assigned Workouts</DialogTitle>
        <DialogContent>
          <div style={{ width: 500, minHeight: 300 }}>
            <div
              className="CoachList"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  // width: "30px",
                  marginRight: 20,
                }}
              >
                Workout Name
              </div>
              <div>Selected Athelets</div>
            </div>

            {allAssignedWkts.map((wkt) => {
              let atheletes = [];

              if (
                wkt.data.assignedById == selectedCoachId &&
                wkt.data?.selectedDates?.includes(selectedDate)
              ) {
                wkt.data.selectedAthletes.forEach((athlete) => {
                  if (athlete.selectedDays.includes(selectedDate)) {
                    atheletes.push(athlete.name);
                  }
                });
                return (
                  <div style={{ margin: 10 }}>
                    {/* {wkt.data.preWorkout.workoutName} */}
                    <div
                      className="CoachList"
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          // width: "0px",
                          marginRight: 20,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowAssignedWktsDetails(true);
                          setWorkout(wkt);
                          console.log("----->", workout);
                        }}
                      >
                        {wkt.data.preWorkout.workoutName}
                      </div>
                      <div>{atheletes.join(",")}</div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showAssignedWktsDetails}
        onClose={() => setShowAssignedWktsDetails(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Assigned Exercises</DialogTitle>
        <DialogContent>
          <div style={{ width: 500, minHeight: 300 }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={
                  {
                    // width: "0px",
                  }
                }
              >
                <div
                  style={{
                    flexDirection: "column",
                  }}
                >
                  {workout &&
                    workout.data.preWorkout.selectedExercises.map((wrkt) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          margin: 10,
                        }}
                      >
                        <div>
                          <img
                            style={{
                              width: 200,
                              height: 120,
                              borderRadius: 5,
                            }}
                            src={
                              wrkt.thumbnail_url
                                ? wrkt.thumbnail_url
                                : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAACHCAMAAADz2UHPAAAAUVBMVEXDw8MAAAC+vr4+Pj7Nzc1bW1vGxsaoqKgeHh5HR0fJyclXV1eNjY27u7ukpKSysrIYGBh7e3thYWGCgoIzMzOdnZ1ycnImJiZPT08SEhJoaGhZY0fTAAABgUlEQVR4nO3YzXaCMBCGYYbEQYafoKho7/9C24ixEGrBBSOL71mq5/AenMRIkgAAAAAAAAAAAAAAAADAx3H6Fl6zRUrzllJWjLEF7ZdrqLBrxuyoTpZ+R0lNu5VjMj8HvGQYONOIYXFO5nNUYrgquu7qZmtUYqyhH93shzVipKa72WWrEWPLPuawhRhxfUwdDY1U0RpTmRm5+JZj1GIP1LnRzVJa2mVu2ugd9pNkUv2YRKyNBobTvb9do+1fKWaKr9Ml9qkY2/YtdBvshR+KkYyC0++rqjEcljJXp2cMfT2vrxkj2a604dWBcxgbxRh2N6KLv5i0wxZqwtgoxsj959LvOo7GjGjH9NswdRWnpyiGLqIbI+fHha/2GLeEsdGKYdeFCxfTFn+/FGP689VrudWLkcP/Lf1hRydmsOG+5JeZzoG8mY/Zp6xzuPprZicKUTkDt/MlXmsVYpzJFzFu/Zg6rRZa/b/2lp5CbOr5zKaeXAEAAAAAAAAAAAAAAADAMt+FkRaLsDLiEAAAAABJRU5ErkJggg=="
                            }
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div>
                            <div style={{ marginLeft: 10 }}>
                              {wrkt.workoutName}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                flexDirection: "column",
                                marginLeft: 10,
                              }}
                            >
                              <div style={{}}>
                                {wrkt.sets[0].reps && "reps"}
                              </div>
                              <div style={{}}>
                                {wrkt.sets[0].weights && "weights(kgs)"}
                              </div>
                              <div style={{}}>
                                {wrkt.sets[0].rest && "rest(secs)"}
                              </div>
                            </div>
                            <div
                              style={{
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginLeft: 10,
                                  }}
                                >
                                  {wrkt.sets.map((set) => (
                                    <div>
                                      <div>
                                        <div style={{ marginLeft: 10 }}>
                                          {set.reps}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginLeft: 10,
                                  }}
                                >
                                  {wrkt.sets.map((set) => (
                                    <div>
                                      <div>
                                        <div style={{ marginLeft: 10 }}>
                                          {set.weights}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginLeft: 10,
                                  }}
                                >
                                  {wrkt.sets.map((set) => (
                                    <div>
                                      <div>
                                        <div style={{ marginLeft: 10 }}>
                                          {set.rest}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            {/* <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {wrkt.sets.map((set) => (
                                  <div>
                                    <div>
                                      <div style={{}}>{set.reps}</div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {wrkt.sets.map((set) => (
                                  <div>
                                    <div>
                                      <div style={{}}>{set.weights}</div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {wrkt.sets.map((set) => (
                                  <div>
                                    <div>
                                      <div style={{}}>{set.rest}</div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Workouts;
