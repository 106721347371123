import logo from "./logo.svg";
import "./App.css";

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { db } from "./utils/firebase";
import {
  login,
  selectUser,
  selectUserType,
  setUserData,
  setUserType,
  setUserVerified,
} from "./features/userSlice";

import { Grid } from "@material-ui/core";
import AdminHome from "./pages/Home/AdminHome";
import Login from "./pages/Login/Login";
import TopBar from "./pages/TopBar/TopBar";
import LinkCoach from "./pages/HeadCoach/LinkCoach";
import AddCoach from "./pages/OnBoard/AddCoach";
import CoachAthletes from "./pages/athletes/CoachAthletes";
import AllAthletes from "./pages/athletes/AllAthletes";
import AddAthlete from "./pages/OnBoard/AddAthlete";
import EditCoach from "./pages/Home/EditCoach";
import Workouts from "./pages/workouts/Workouts";

function App() {
  const user = useSelector(selectUser);
  const userType = useSelector(selectUserType);
  const dispatch = useDispatch();

  console.log(user, userType);

  var d = new Date();
  d.setHours(0, 0, 0, 0);
  //(new Date().setHours(0, 0, 0, 0));
  const [selectedDate, setselectedDate] = useState(
    new Date().setHours(0, 0, 0, 0)
  );

  const toggle_date = (date) => {
    setselectedDate(date);
  };

  useEffect(() => {
    // if (user) {
    //   db.collection("admins")
    //   .where("email", "==", user)
    //   .get()
    //   .then(function (querySnapshot) {
    //     querySnapshot.forEach(function (doc) {
    //       dispatch(
    //         setUserData({
    //           id: doc.id,
    //           data: doc.data(),
    //         })
    //       );
    //     });
    //   })
    //   .catch(function (error) {
    //     console.log("Error getting documents: ", error);
    //   })
    dispatch(setUserType("admin"));
  }, [user]);

  useEffect(() => {
    const getData = async () => {
      try {
        const user = await localStorage.getItem("user");
        const userType = await localStorage.getItem("userType");
        const userVerified = await localStorage.getItem("userVerified");

        if (user != null) {
          dispatch(login(user));
        }
        if (userType != null) {
          dispatch(setUserType(userType));
        }
        if (userVerified != null) {
          dispatch(setUserVerified(userVerified == "true" ? true : false));
        }
      } catch (e) {
        console.log("error" + e);
      }
    };
    getData();
  }, []);
  const NotFound = () => {
    return <h4>404 Not Found</h4>;
  };

  function RoutesComp({ AdminComp }) {
    if (userType) {
      console.log({ userType });
      return (
        <div>
          <div className="TopNavBar">
            <TopBar />{" "}
          </div>
          <div className="home__container">{AdminComp}</div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Loading</h1>
        </div>
      );
    }
  }

  return (
    <div>
      {!user ? (
        <Router>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>

            <Route component={NotFound} />
          </Switch>
        </Router>
      ) : (
        <Router>
          <Switch>
            <Route exact path="/">
              <RoutesComp AdminComp={<AdminHome />} />
            </Route>
            <Route path="/test">
              <RoutesComp AdminComp={<AdminHome />} />
            </Route>
            <Route path="/linkcoach">
              <RoutesComp AdminComp={<LinkCoach />} />
            </Route>
            <Route path="/addcoach">
              <RoutesComp AdminComp={<AddCoach />} />
            </Route>
            <Route path="/addAthlete">
              <RoutesComp AdminComp={<AddAthlete />} />
            </Route>
            <Route path="/athletes">
              <RoutesComp AdminComp={<CoachAthletes />} />
            </Route>
            <Route path="/all-athletes">
              <RoutesComp AdminComp={<AllAthletes />} />
            </Route>
            <Route path="/edit-coach">
              <RoutesComp AdminComp={<EditCoach />} />
            </Route>
            <Route path="/workouts">
              <RoutesComp AdminComp={<Workouts />} />
            </Route>

            <Route component={NotFound} />
          </Switch>
        </Router>
      )}
    </div>
  );
}

export default App;
