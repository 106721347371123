import React, { useEffect, useState } from "react";
import { auth, db } from "../../utils/firebase";
import { selectUserData, selectUserType } from "../../features/userSlice";
import { useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import OpenDialog from "../../components/dialogbox";
import EditIcon from "@material-ui/icons/Edit";
import "./LinkCoach.css";

import styled from "styled-components";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import SelectSearch, { fuzzySearch } from "react-select-search";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  DialogContentText,
} from "@material-ui/core";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
const InputWrapper = styled("div")`
  width: 350px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 4%;

  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Listbox = styled("ul")`
  width: 350px;
  margin: 2px 0 0;
  margin-left: 2.1%;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 150px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected="true"] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus="true"] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;

  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
  margin-left: 4%;
  margin-top: 20px;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function LinkCoach() {
  const userType = useSelector(selectUserType);
  const [CoachList, setCoachList] = useState([]);
  const [HeadCoachList, setHeadCoachList] = useState([]);

  const [CoachList_count, setCoachList_count] = useState(30);
  const [AthleteDetails, setAthleteDetails] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [search, setsearch] = useState("");
  const [SearchList, setSearchList] = useState(null);
  const [SearchLoading, SetSearhLoading] = useState(false);
  const [reload, setreload] = useState(false);
  const [CoachLink, setCoachLink] = useState([]);

  // useEffect(() => {
  //   CoachList?.filter((coach) => {
  //     return coach.data.name.toLowerCase().includes(search);
  //   });
  // }, [search]);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: CoachList,
    getOptionLabel: (option) => option.name,
  });

  useEffect(() => {
    db.collection("coaches")
      .get()
      .then((snap) => {
        setCoachList(
          snap.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
            value: doc.id,
            name: doc.data().name,
          }))
        );

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userType, reload]);

  useEffect(() => {
    db.collection("HeadCoaches")
      .get()
      .then((snap) => {
        setHeadCoachList(
          snap.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
            value: doc.id,
            name: doc.data().name,
          }))
        );

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userType, reload]);

  // useEffect(() => {
  //   setSearchList(CoachList);
  // }, [CoachList]);

  const GetOptions = (id) => {
    return new Promise((resolve, reject) => {
      let temp = [...CoachList];
      temp.map((tmp, idx) => {
        console.log(tmp, id);
        if (tmp.id == id) {
          console.log(temp);
          resolve(temp.slice(idx, 1));
        }
      });
    });
  };

  if (Loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <div className="CoachList__container">
        {console.log(CoachList)}

        <SelectSearch
          options={CoachList}
          onChange={(d, f) => {
            console.log(d, f);
            let temp = [...CoachLink];
            let tmp = {};
            tmp = f;
            tmp["options"] = GetOptions(d);
            // GetOptions(d).then((data) => {
            //   tmp["options"] = data;
            //   temp[0] = tmp;
            //   console.log(data);
            //   setCoachLink(temp);
            // });
            HeadCoachList.map((data) => {
              console.log("st", data.id, d);
              if (data.id == d) {
                tmp["data"]["listOfCoaches"] = data.data.listOfCoaches;
              }
            });
            temp[0] = tmp;
            setCoachLink(temp);

            //temp.push(tmp);
          }}
          value={""}
          name="Choose a HeadCoach"
          search
          filterOptions={fuzzySearch}
          placeholder="Search for a HeadCoach"
          // multiple={true}
        />
        {console.log(HeadCoachList)}

        {CoachLink?.map((coach, idx) => (
          <div
            style={{
              width: 600,
              margin: 20,
            }}
          >
            {console.log(CoachLink)}
            <h3>HeadCoach : {coach.data.name}</h3>
            <div style={{}}>
              <div> Search for Sub-Coach</div>
              <SelectSearch
                options={CoachList}
                onChange={(d, f) => {
                  // console.log(d, f);
                  // let temp = [...CoachLink];
                  // let tmp = {};
                  // tmp = f;
                  // temp.push(tmp);
                  // setCoachLink(temp);
                  let temp = [...CoachLink];
                  var index = d.indexOf(coach.id);
                  if (index !== -1) {
                    d.splice(index, 1);
                  }
                  temp[idx]["listOfCoaches"] = [];
                  temp[idx]["listOfCoaches"] = d;
                  setCoachLink(temp);
                }}
                value={coach.data.listOfCoaches}
                name="Choose a HeadCoach"
                search
                filterOptions={fuzzySearch}
                placeholder="Search for a Coach"
                printOptions="on-focus"
                multiple={true}
                closeOnSelect={false}
              />
            </div>
          </div>
        ))}

        <div
          style={{
            display: "flex",
            height: 30,
            marginTop: 50,
          }}
        >
          <button
            style={{
              border: "none",
              outline: "none",
              width: 100,
              backgroundColor: "transparent",
            }}
            onClick={() => {
              let temp = [];
              setCoachLink([]);
            }}
          >
            RESET
          </button>
          <button
            style={{
              border: "none",
              outline: "none",
              width: 100,
              height: 40,
              backgroundColor: "var(--primarycolor)",
              color: "var(--secondarycolor)",
              borderRadius: 7,
              boxShadow: "0px 0px 2px 0px rgb(0,0,0,0.2)",
            }}
            onClick={() => {
              CoachLink?.map((coach) => {
                console.log(coach);

                db.collection("HeadCoaches")
                  .doc(coach.id)
                  .set({
                    CoachId: coach.id,
                    listOfCoaches: coach.listOfCoaches,
                  })
                  .then(() => {
                    db.collection("coaches")
                      .doc(coach.id)
                      .update({
                        HeadCoach:
                          coach.listOfCoaches.length > 0 ? true : false,
                      });
                    alert("Success");
                  });
              });
            }}
          >
            LINK COACH
          </button>
        </div>
      </div>
    </div>
  );
}

export default LinkCoach;
