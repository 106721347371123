import React, { useEffect, useState } from "react";
import { auth, db } from "../../utils/firebase";
import { selectUserData, selectUserType } from "../../features/userSlice";
import { useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import OpenDialog from "../../components/dialogbox";
import EditIcon from "@material-ui/icons/Edit";
import "./CoachAthlete.css";
// import AthletesList from "./AthetesList";
import SearchIcon from "@material-ui/icons/Search";
import { useLocation } from "react-router-dom";
import PaymentsList from "./Payments";
function CoachAthletes() {
  const userType = useSelector(selectUserType);
  const [CoachList, setCoachList] = useState(null);
  const [CoachList_count, setCoachList_count] = useState(30);
  const [AthleteDetails, setAthleteDetails] = useState(null);
  const [AthleteList, setAthleteList] = useState(null);

  const [Loading, setLoading] = useState(true);
  const [search, setsearch] = useState("");
  const [SearchList, setSearchList] = useState(null);
  const [SearchLoading, SetSearhLoading] = useState(false);
  const [reload, setreload] = useState(false);

  const location = useLocation();
  useEffect(() => {
    AthleteList?.filter((coach) => {
      return coach.name.toLowerCase().includes(search);
    });
  }, [search]);

  useEffect(() => {
    if (location.state?.Athletes) {
      let temp = [];
      console.log(location.state);
      db.collection("athletes")
        .orderBy("name", "asc")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((athlete) => {
            if (location.state?.Athletes?.includes(athlete.id)) {
              let currentID = athlete.id;
              let appObj = { ...athlete.data(), ["id"]: currentID };
              temp.push(appObj);
            }
          });
          setAthleteList(temp);
          console.log(temp);
          setLoading(false);
        });
      //setAthleteList(location.state?.Athletes);
    } else {
      db.collection("coaches")
        .get()
        .then((snap) => {
          setCoachList(
            snap.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          );

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userType, reload, location.state?.data]);

  useEffect(() => {
    setSearchList(AthleteList);
  }, [AthleteList]);

  const getSearchList = async (e) => {
    SetSearhLoading(true);
    if (e.target.value) {
      const names = await AthleteList?.filter((coach) => {
        return coach.data.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });

      setSearchList(names);
      SetSearhLoading(false);
    } else {
      setSearchList(AthleteList);
      SetSearhLoading(false);
    }
  };
  if (Loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <div className="CoachList__container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {console.log(AthleteList)}
          <div
            style={{
              margin: 20,

              padding: 5,
              display: "flex",
              alignItems: "center",
              border: "1px solid black",
              width: "500px",
            }}
          >
            <input
              style={{
                width: "100%",

                fontSize: 20,
                outline: "none",
                border: "none",
              }}
              onChange={getSearchList}
            />
            <SearchIcon
              style={{
                width: 40,
                height: 40,
              }}
            />
          </div>
          <div
            style={{
              fontSize: 18,
            }}
          >
            {AthleteList?.length} search results loaded
          </div>
        </div>
        <div
          className="CoachList"
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              width: "30px",
              marginRight: 20,
            }}
          >
            S.no
          </div>
          <div>Name</div>
          <div style={{ width: 250 }}>Email</div>

          <div
            style={{
              width: 120,
            }}
          >
            Payments
          </div>
          <div
            style={{
              width: 70,
              textAlign: "center",
            }}
          >
            Edit
          </div>
          <div
            style={{
              width: 70,
              textAlign: "center",
            }}
          >
            Active
          </div>
        </div>

        {SearchLoading ? (
          <Spinner />
        ) : (
          SearchList?.map((doc, idx) => (
            <div
              lkey={idx}
              className="CoachList"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "30px",
                  marginRight: 20,
                }}
              >
                {idx + 1}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <img src={`${doc?.imageUrl}`} width="20" height="20" />{" "}
                {doc?.name}
              </div>
              <div style={{ width: 250 }} className="email_field">
                {doc?.email}
              </div>

              <div
                style={{
                  width: 120,
                  cursor: "pointer",
                }}
              >
                <PaymentsList title="view" CoachName={doc.name} data={doc.id} />
              </div>
              <div
                style={{
                  width: 70,
                  textAlign: "center",
                }}
              >
                <EditIcon />
              </div>

              <div
                style={{
                  width: 70,
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  db.collection("athletes")
                    .doc(doc.id)
                    .update({
                      active:
                        doc?.active !== undefined
                          ? doc?.active === true
                            ? false
                            : true
                          : false,
                    })
                    .then((snap) => {
                      console.log(snap);
                      let temp = [...SearchList];
                      temp[idx].active = temp[idx].active;
                      setreload(!reload);
                    });
                }}
              >
                {doc?.active !== undefined
                  ? doc?.active === true
                    ? "Disable"
                    : "Enable"
                  : "Disable"}
              </div>
            </div>
          ))
        )}

        {/* <div>
          <div
            style={{
              display: "flex",
            }}
            onClick={() => {
              if (CoachList.length - CoachList_count > 30) {
                setCoachList_count(CoachList_count + 30);
              } else {
                setCoachList_count(CoachList.length);
              }
            }}
          >
            {CoachList_count}Loaded+ LoadMore
          </div>
        </div> */}

        <div>{}</div>
      </div>
    </div>
  );
}

export default CoachAthletes;
