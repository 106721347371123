import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { db } from "../../utils/firebase";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function PaymentsList({ title, data, CoachName }) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [dialog_data, setdialog_data] = React.useState(data ? data : null);
  const [name, setname] = React.useState(title);
  const history = useHistory();
  const [paymentData, setpaymentData] = React.useState(null);
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);

    if (data) {
      db.collection("payments")
        .where("athlete", "==", data)
        .get()
        .then((snap) => {
          var payments_data = [];
          var completed = [];
          var upcoming = [];
          var pending = [];
          var today = [];
          var data = [];
          snap.docs.forEach((doc) => {
            let appObj = { ...doc.data(), ["id"]: doc.id };
            payments_data.push(appObj);
          });
          console.log(payments_data);
          payments_data.sort((a, b) => {
            return (
              new Date(a.date.seconds * 1000) - new Date(b.date.seconds * 1000)
            );
          });
          setTimeout(() => {
            payments_data.forEach((id) => {
              console.log(payments_data);

              if (id.status == "paid") {
                data.push({
                  paymentstatus: "paid",
                  amount: id.amt,
                  date: moment(new Date(id.date.seconds * 1000)).format(
                    "DD-MM-YYYY"
                  ),
                });
              } else {
                if (
                  moment(
                    moment(new Date()).format("DD-MM-YYYY"),
                    "DD-MM-YYYY"
                  ).valueOf() <=
                    id.date.seconds * 1000 &&
                  moment(
                    moment(new Date()).add(1, "days").format("DD-MM-YYYY"),
                    "DD-MM-YYYY"
                  ).valueOf() >=
                    id.date.seconds * 1000
                ) {
                  data.push({
                    paymentstatus: "today",
                    amount: id.amt,
                    date: moment(new Date(id.date.seconds * 1000)).format(
                      "DD-MM-YYYY"
                    ),
                  });
                } else if (
                  id.date.seconds * 1000 <
                  moment(new Date()).valueOf()
                ) {
                  data.push({
                    paymentstatus: "pending",
                    amount: id.amt,
                    date: moment(new Date(id.date.seconds * 1000)).format(
                      "DD-MM-YYYY"
                    ),
                  });
                } else {
                  data.push({
                    paymentstatus: "upcoming",
                    amount: id.amt,
                    date: moment(new Date(id.date.seconds * 1000)).format(
                      "DD-MM-YYYY"
                    ),
                  });
                }
              }
            });
            setpaymentData(data);
          }, 2000);
        });
    }
    // setScroll(scrollType);
    // let temp = [];

    // db.collection("athletes")
    //   .orderBy("name", "asc")
    //   .get()
    //   .then((snapshot) => {
    //     snapshot.docs.forEach((athlete) => {
    //       if (dialog_data?.includes(athlete.id)) {
    //         let currentID = athlete.id;
    //         let appObj = { ...athlete.data(), ["id"]: currentID };
    //         temp.push(appObj);
    //       }
    //     });
    //     setAthleteDetails(temp);
    //     console.log(temp);
    //   });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button onClick={handleClickOpen("body")}>{name}</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {CoachName && CoachName}
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div
              className="CoachList"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "30px",
                  marginRight: 20,
                }}
              >
                S.no
              </div>
              <div style={{ width: 100, textAlign: "center" }}>Status</div>
              <div style={{ width: 100, textAlign: "center" }}>amount</div>

              <div style={{ width: 100, textAlign: "center" }}>Date</div>
            </div>
            {paymentData &&
              paymentData.map((item, idx) => (
                <div
                  key={idx}
                  className="CoachList"
                  style={{
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      width: "30px",
                      marginRight: 20,
                    }}
                  >
                    {idx + 1}
                  </div>
                  {console.log(item)}
                  <div style={{ width: 100, textAlign: "center" }}>
                    {item.paymentstatus}
                  </div>
                  <div style={{ width: 100, textAlign: "center" }}>
                    {item.amount}
                  </div>

                  <div
                    style={{ width: 100, textAlign: "center" }}
                    className="email_field"
                  >
                    {item.date}
                  </div>
                </div>
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
