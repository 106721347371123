import React from "react";
import { auth } from "../../utils/firebase";
import {
  logout,
  selectUserData,
  selectUserType,
} from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./TopBar.css";
import BarComponent from "./BarComponent";

export default function TopBar() {
  const userData = useSelector(selectUserData);
  const userType = useSelector(selectUserType);
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className="topbar__container">
      <div>
        <div
          className="topbar"
          style={{
            display: "flex",
          }}
        >
          <BarComponent name="Home" path="" />

          <BarComponent name="Athletes" path="all-athletes" />
          <BarComponent name="LinkCoach" path="linkcoach" />
          <BarComponent name="OnBoard Coach" path="addcoach" />
          <BarComponent name="OnBoard Athlete" path="addAthlete" />
          <BarComponent name="Workouts" path="workouts" />

          <div
            className="drawer__container"
            onClick={() => {
              auth.signOut();
              dispatch(logout());
              history.push("/");
            }}
          >
            <div
              style={{
                padding: 30,
              }}
            >
              Sign Out
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
