import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  selectUser,
  selectUserType,
  selectUserVerified,
  setUserType,
  setUserVerified,
} from "../../features/userSlice";
import "./AddCoach.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { auth, db } from "../../utils/firebase";
import secret from "../../secret.json";
import { Avatar } from "@material-ui/core";
import Spinner from "../../components/Spinner";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Password must be 3 characters at minimum")
    .required("Password is required"),
});

function AddCoach() {
  const user = useSelector(selectUser);
  const userType = useSelector(selectUserType);
  const userVerified = useSelector(selectUserVerified);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState(null);
  const history = useHistory();
  const [isLoading, setisLoading] = useState(false);
  const [CoachAdded, setCoachAdded] = useState(false);
  const [error, seterror] = useState(false);
  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const loginUser = (values) => {
    console.log("Logging on ...", values.email, values.password);

    if (values.email == "sun@gmail.com" && values.password == "test") {
      setLoading(true);
      setTimeout(() => {
        dispatch(setUserType("admin"));
        dispatch(login(values.email1));
      });
    } else {
      alert("wrong");
    }

    // auth
    //   .signInWithEmailAndPassword(values.email, values.password)
    //   .then((auth) => {
    //     db.collection("coaches")
    //       .where("email", "==", values.email)
    //       .get()
    //       .then((snap) => {
    //         if (!snap.empty) {
    //           setLoading(true)
    //           setTimeout(() =>{
    //           dispatch(setUserType("coach"));
    //           dispatch(login(auth.user.email));
    //           },1000);
    //         } else {
    //           db.collection("athletes")
    //             .where("email", "==", values.email)
    //             .get()
    //             .then((snap) => {
    //               if (!snap.empty) {
    //                 setLoading(true)

    //                 setTimeout(() =>{
    //                   snap.forEach(function (doc) {
    //                     dispatch(setUserVerified(doc.data().verified));
    //                   });

    //                   dispatch(setUserType("athlete"));
    //                   dispatch(login(auth.user.email));
    //                 },1000)

    //               } else {
    //                 alert(
    //                   "Check your email and password",
    //                 )
    //               }
    //             });
    //         }
    //       });
    //   })
    //   .catch((e) => alert(e.message));
  };

  function forgotPass() {
    console.log("Clicked on forgot password");
  }

  useEffect(() => {
    db.collection("counter")
      .doc("Ecea3D4sVoY1rb2BCP6S")
      .get()
      .then(function (doc) {
        if (doc.exists) {
          console.log(doc.data().count);
          setPin(doc.data().count);
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, [user]);
  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid not in email address format")
      .required("Email is required"),
    password: Yup.string()
      .min(3, "Password must be 3 characters at minimum")
      .required("Password is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      //.min(3, "Password must be 3 characters at minimum")
      .required("Phone number is required"),
  });
  if (error && !CoachAdded)
    return (
      <div>
        <h2>Account creation Failed</h2>
        <h4>Please try again later</h4>
      </div>
    );
  if (CoachAdded && !error)
    return (
      <div>
        <h2>Account creation Successful</h2>
        <h4>Coach Id is {pin}</h4>
      </div>
    );
  if (isLoading)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  else
    return (
      <div className="signup">
        <h1>Onboard a new coach</h1>
        <Avatar />
        <Formik
          initialValues={{
            email: "",
            password: "",
            password2: "",
            name: "",
            phone: "",
            address: "",
            date: "",
            gender: "",
            CoachType: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            setisLoading(true);
            console.log(values);
            // alert("Form is validated! Submitting the form...");
            auth
              .createUserWithEmailAndPassword(values.email, values.password)
              .then((auth) => {
                db.collection("coaches").add({
                  name: values.name,
                  phone: values.phone,
                  email: values.email,
                  gender: values.gender,
                  dob: values.date,
                  address: values.address,
                  imageUrl: "",
                  sports: [],
                  pendingInvites: 0,
                  listOfAthletes: [],
                  pin,
                  CoachType: values.CoachType,
                });

                db.collection("counter")
                  .doc("Ecea3D4sVoY1rb2BCP6S")
                  .update({
                    count: pin + 1,
                  });
                //history.push("CoachFlow");
                //navigation.navigate("CoachInfo");

                //dispatch(setUserDetails(auth.user));
                setisLoading(false);
                setCoachAdded(true);
              })

              .catch((e) => {
                setisLoading(false);
                seterror(true);
                console.log(e);
              });
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="Name">Name</label>
                <Field
                  type="name"
                  name="name"
                  placeholder="Coach Name"
                  className="form-control input-field"
                />
              </div>

              {/* <div className="form-radiogroup">
              <Field
                name="decision"
                render={({ field }) => (
                  <>
                    <div className="radio-item">
                      <input
                        {...field}
                        id="Athlete"
                        value="Athlete"
                        checked={field.value === "Athlete"}
                        name="type"
                        type="radio"
                      />
                      <label htmlFor="Athlete">Athlete</label>
                    </div>

                    <div className="radio-item">
                      <input
                        {...field}
                        id="Coach"
                        value="Coach"
                        checked={field.value === "Coach"}
                        type="radio"
                      />
                      <label htmlFor="Coach">Coach</label>
                    </div>
                  </>
                )}
              />
            </div> */}

              <div className="signup__heading">Gender</div>

              <div className="form-radiogroup">
                <Field
                  name="decision"
                  render={({ field }) => (
                    <>
                      <div className="radio-item">
                        {console.log(values)}
                        <input
                          {...field}
                          id="Male"
                          value="Male"
                          // checked={field.value === "Male"}
                          name="gender"
                          type="radio"
                        />
                        <label htmlFor="Male">Male</label>
                      </div>

                      <div className="radio-item">
                        <input
                          {...field}
                          id="Female"
                          value="Female"
                          //checked={field.value === "Female"}
                          type="radio"
                          name="gender"
                        />
                        <label htmlFor="Female">Female</label>
                      </div>
                    </>
                  )}
                />
              </div>
              <div className="signup__heading">Coach Type</div>

              <div className="form-radiogroup">
                <Field
                  name="decision"
                  render={({ field }) => (
                    <>
                      <div className="radio-item">
                        {console.log(values)}
                        <input
                          {...field}
                          id="Strength and Conditioning"
                          value="Strength and Conditioning"
                          // checked={field.value === "Male"}
                          name="CoachType"
                          type="radio"
                        />
                        <label htmlFor="Strength and Conditioning">
                          Strength and Conditioning
                        </label>
                      </div>

                      <div className="radio-item">
                        <input
                          {...field}
                          id="Physiotherapist"
                          value="Physiotherapist"
                          //checked={field.value === "Female"}
                          type="radio"
                          name="CoachType"
                        />
                        <label htmlFor="Physiotherapist">Physiotherapist</label>
                      </div>
                      <div className="radio-item">
                        <input
                          {...field}
                          id="Rehab"
                          value="Rehab"
                          //checked={field.value === "Female"}
                          type="radio"
                          name="CoachType"
                        />
                        <label htmlFor="Rehab">Rehab</label>
                      </div>
                    </>
                  )}
                />
              </div>

              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <Field
                  type="phone"
                  name="phone"
                  placeholder="Enter Phone Number"
                  className="form-control input-field"
                />
                {touched.phone && errors.phone}
              </div>

              <div className="form-group">
                <label htmlFor="Address">Address</label>
                <Field
                  type="address"
                  name="address"
                  placeholder="Enter Address"
                  className="form-control input-field"
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  className={`form-control input-field ${
                    touched.email && errors.email ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Field
                  type="password"
                  name="password"
                  placeholder="Enter password"
                  className={`form-control input-field ${
                    touched.password && errors.password ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  component="div"
                  name="password"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  className="signup__button"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Please wait..." : "Add Coach"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
}

export default AddCoach;
